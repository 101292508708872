@import "../../styles/styles.scss";

.empty-basket-header {
  font-size: 4rem;
  animation: blinker 2s ease-in infinite;
}

@keyframes blinker {
  50% {
    color: $accent-color;
  }
}
