@import "../../styles/styles.scss";

.back-indulgences {
  margin: 2rem 0;
  span {
    color: $accent-color;
    font-size: $medium;

    &:hover {
      color: $primary-color;
    }
  }
}

.back-indulgences-inverse {
  span {
    color: $primary-color;

    &:hover {
      color: $accent-color;
    }
  }
}
