@import "../../../styles/styles.scss";

.input {
  padding: 15px 0 0;
  width: 100%;
  height: 4rem;

  input,
  label {
    font-family: $body-font;
    font-size: $small;
    padding: 10px 5px;
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $primary-color;
    color: $primary-color;
    font-weight: 500;
    outline: none;
    width: 100%;

    & + label {
      color: $primary-color;
      pointer-events: none;
      position: relative;
      top: -40px;
      @include transition-ease;
    }

    // autofill background
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px lighten($color: $primary-color, $amount: 53%) inset;
      border-radius: 2px;
    }

    &:focus,
    &:not(:placeholder-shown),
    &:-webkit-autofill {
      & + label {
        top: -70px;
        padding: 10px 0;
        color: $accent-color;
      }
    }
  }

  .input-error {
    border-bottom: 1px solid $error-color;
  }
}

.error-message {
  color: $error-color;
  font-weight: bold;
}
