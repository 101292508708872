@import "../../styles/styles.scss";

.header {
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 3px 3px -3px gray;
  background-color: $secondary-color;
}

.header-ul {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0.7rem;
  margin-bottom: 0;

  li {
    list-style: none;
    margin-right: 4rem;
    font-size: $medium;
    font-weight: bold;

    a {
      color: $primary-color;
    }

    a:hover,
    a:active {
      color: $accent-color;
    }
  }
}

.basket {
  max-width: 0;
  overflow: hidden;
  transition: max-width 4s linear;
}

.basket-show {
  max-width: 100vh;
}

.header-mobile {
  display: none;
}

@media all and (max-width: 1020px) {
  .header-ul {
    justify-content: space-between;
    align-items: center;

    li {
      margin-right: 0;
    }
  }
}

@include media-m {
}

@include media-s {
  .header-ul {
    justify-content: space-between;
    align-items: center;

    li {
      margin-right: 0;
    }
  }

  .header-normal {
    display: none;
  }

  .header-mobile {
    display: initial;
  }

  .basket-btn {
    padding: 0.3rem 2rem;
  }

  .basket {
    position: absolute;
  }

  .basket-show {
    position: initial;
  }
}
