@import "../../styles/styles.scss";

.basket-container {
  background-color: $primary-color;
}

.logo-centered {
  display: flex;
  justify-content: center;
}

@include media-m {
}
