@import "../../styles/styles.scss";

.accept-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 2rem;

  h1 {
    font-weight: bold;
  }
}

.accept-icon {
  color: $accent-color;
  height: 8rem;
}

@include media-s {
  .accept-icon {
    height: 4rem;
  }

  .accept {
  }
}
