@import "../../../styles/styles.scss";

.checkout-form {
  background-color: $secondary-color;
  margin: 2rem auto;
  padding: 2rem;
  width: 50%;
  max-width: 50rem;

  .input {
    padding-top: 2px;
  }
}

.checkout-terms {
  font-size: $extra-small;
  color: $primary-color;
}

.checkout-form-pay {
  display: flex;
  margin: 1.3rem 0;
}

.checkout-form-total {
  font-size: x-large;
  margin-left: 2rem;
}

.checkout-form-terms {
  height: 400px;
  overflow-y: auto;
}

@include media-m {
  .checkout-form {
    width: 70%;
  }

  .checkout-form-pay {
    flex-direction: column;
  }

  .checkout-form-total {
    margin-left: 0;
  }
}

@include media-s {
  .checkout-form {
    width: 90%;
  }
}
