@import "../../styles/styles.scss";

.top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
  background-size: auto 70%;
  background-color: $secondary-color;

  h1,
  h2 {
    color: $primary-color;
  }
}

.main-logo {
  width: 70vh;
  max-width: 50rem;
}

.slogan {
  text-align: center;
  margin-top: 1rem;
  font-size: 3rem;
}

.slogan-spec {
  color: $accent-color;
}

@include media-m {
  .main-logo {
    // width: 60vh;
    max-width: 35rem;
  }
}

@include media-s {
  .main-logo {
    width: 50vh;
    max-width: 20rem;
  }

  .slogan {
    display: none;
  }
}
