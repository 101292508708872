@import "../../../styles/styles.scss";

.indulgence-card {
  width: 26%;
  height: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $secondary-color;
  padding: 1rem 2.2rem;
  transition: box-shadow 0.2s ease-out;

  h2 {
    color: $primary-color;
    text-align: center;
  }

  p {
    color: black;
  }
}

.indulgence-logo {
  width: 200px;
  height: 200px;
  text-align: center;
}

.indulgence-card-content {
  width: 100%;
  overflow: hidden;
}

.indulgence-card-price {
  margin-top: auto;
}

.added-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $accent-color;
  height: 2.5rem;
  opacity: 0;
  transition: height 1s;
}

.added-icon.added {
  opacity: 1;
  height: 2rem;
}

.basket-btn.added,
.basket-btn.added:hover {
  background-color: $primary-color;
  color: transparent;
  transition: all 0s;
}

@include media-m {
  .indulgence-card {
    width: 40%;
  }
}

@include media-s {
  .indulgence-card {
    width: 100%;
  }
}
