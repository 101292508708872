@import "../../../styles/styles.scss";

.checkout-item {
  background-color: $secondary-color;
  margin: 2rem auto;
  padding: 2rem;
  width: 50%;
  position: relative;
  max-width: 50rem;

  h1,
  h2 {
    color: $primary-color;
  }
}

.checkout-item-x {
  color: $secondary-color-light-p;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2rem;
  margin-right: 2rem;
  height: 1.3rem;

  &:hover {
    color: $accent-color;
    cursor: pointer;
  }
}

.checkout-item-recipient {
  width: 50%;
}

.checkout-item-top {
  display: flex;
  flex-wrap: wrap;
}
.checkout-item-description {
  flex-grow: 1;
  flex-basis: 50%;
  h1 {
    margin-bottom: 2rem;
  }
  p {
    color: $primary-color;
  }
}

.checkout-item-preview {
  margin-left: auto;
  flex-basis: 150px;
  align-self: flex-end;
}

.checkout-item-bottom {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
}

.checkout-item-price {
  color: $accent-color;
  font-size: x-large;
}

@include media-m {
  .checkout-item-bottom {
    justify-content: space-between;
  }

  .checkout-item-x {
    top: auto;
    bottom: 0;
    margin-top: auto;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .checkout-item-description {
    h2 {
      font-size: x-large;
    }
    p {
      font-size: medium;
    }
  }

  .checkout-item-recipient {
    width: 100%;
  }

  .checkout-item-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}

@include media-m {
  .checkout-item {
    width: 70%;
  }
}

@include media-s {
  .checkout-item {
    width: 90%;
  }

  .indulgence-logo {
    width: 40%;
  }

  .checkout-item-description {
    h2 {
      font-size: medium;
    }
    p {
      font-size: small;
    }
  }
}
