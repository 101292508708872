@import "../../../styles/styles.scss";

.paginated-list-items {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.2rem;
  column-gap: 1.2rem;
  justify-content: center;
}

.indulgence-list-pagination {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  min-height: 3rem;
}

.indulgence-list-load-more {
  font-weight: bold;
  color: $secondary-color;
  font-size: $medium;
  border-radius: 10px;
  flex: 1;
}

.indulgence-list-load-more-btn {
  &:hover {
    cursor: pointer;
    color: $accent-color;
  }
}

@include media-s {
  .paginated-list-items {
    flex-direction: column;
  }
}
