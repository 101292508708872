@import "../../styles/styles.scss";

.loader-container {
  height: 10vh;
  margin-top: 10rem;

  .spinner::after {
    border-color: $accent-color $primary-color lighten($color: $primary-color, $amount: 50%);
  }
}

@include media-m {
}
