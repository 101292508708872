@import "../../../../styles/styles.scss";

.list-show-less {
  position: fixed;
  font-size: $extra-small;
  bottom: 3rem;
  left: 1rem;
  background-color: $accent-color;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  transition: opacity 0.4s ease-in;
  border: 1px solid $accent-color;

  &:hover {
    background-color: $secondary-color;
    color: $primary-color;
    border: 1px solid $primary-color;

    cursor: pointer;
  }
}

.list-show-less-hide {
  opacity: 0;
}

.list-show-less-hide-f {
  transition: opacity 0s;
}

.list-show-less-static {
  position: initial;
  margin-left: 1rem;
}

@include media-s {
  .list-show-less {
    left: 0.3rem;
    bottom: 0.3rem;
  }

  .list-show-less-static {
    margin-left: 0.3rem;
  }
}
