@import "../../styles/styles.scss";

.main {
  display: flex;
  overflow-x: hidden;
}

.cookie-consent-body {
  bottom: 0px;
  align-items: baseline;
  background: $secondary-color;
  color: $primary-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.cookie-consent-content {
  flex: 1 0 300px;
  margin: 15px;
}

.cookie-consent-btn {
  background: $accent-color;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
  margin: 15px;
}

@include media-m {
  .main {
    display: block;
  }
}
