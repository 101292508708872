@import "../../styles/styles.scss";

#faq {
  background-color: $secondary-color;
  padding: 6rem 0.5rem;
}

.faq-h2 {
    color: $primary-color;
}

.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

@include media-m {
}
