@import "../../../styles/styles.scss";

.faq-button {
  background-color: $primary-color;
  color: $secondary-color;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  &::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.4s;
  }
}

.faq-button-h3 {
  font-size: large;
}

.faq-button.faq-active {
  &::after {
    transform: rotate(90deg);
  }
}
.faq-active,
.faq-button:hover {
  background: darken($primary-color, 10%);
}

.faq-panel {
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s;
  transition: padding 0.4s;
  padding: 0 1rem;
}

.faq-panel-active {
  max-height: 100vh;
  padding: 1rem;
}

@include media-m {
}
