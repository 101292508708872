@import "../../../../styles/styles.scss";

.checkout-form-promo-container {
  display: flex;
  justify-content: flex-end;
}

.checkout-form-promo {
  width: 40%;
}

@include media-m {
  .checkout-form-promo {
    width: 100%;
  }
}
