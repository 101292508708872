@import "./variables";
@import "~bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: $body-font;
  background-color: $background-color;
}

.centered {
  text-align: center;
}

.content {
  margin-top: 0.4rem;
}

.container,
.container-card {
  width: 70%;
}

.container-card {
  background-color: $secondary-color;
  margin: 2rem auto;
  padding: 2rem;

  h1,
  h2,
  h3,
  p {
    color: $primary-color;
  }
}

.container-centered {
  display: flex;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.section {
  background-color: $primary-color; //#e9e8e8;
  padding: 7rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.roll-fill {
  fill: $secondary-color;
}

.roll-fill-side {
  fill: $accent-color;
}

.accent {
  color: $accent-color;
}

.accent-hover {
  color: $accent-color;
  &:hover {
    color: $primary-color;
    cursor: pointer;
  }
}

a:hover.accent {
  color: $primary-color;
}

@include media-s {
  .container,
  .container-card {
    width: 100%;
  }

  .container-card {
    h1 {
      font-size: $large;
    }
    h2,
    h3 {
      font-size: $medium;
    }
  }
}
