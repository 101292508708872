@import "../../styles/styles.scss";

.cancel-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 2rem;

  h1 {
    font-weight: bold;
  }
}

.x-icon {
  color: $red;
  height: 1.5rem;
}

@include media-s {
  .cancel-header {
    gap: 1rem;

    h1 {
      font-size: $medium;
    }
  }
}
