@import "../../../../styles/styles.scss";

.default-button {
  @include button;
  height: 2.5rem;
}

.button-loader.button-loader-loading {
  background-color: $accent-color;
  color: transparent;
  transition: all 0s;
}

.button-loader.button-loader-loading:hover {
  background-color: $accent-color;
  color: transparent;
  transition: all 0s;
}
