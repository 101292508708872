@import "../../../styles/styles.scss";

.small-top {
  background-color: $secondary-color;
}

.small-top-logo {
  width: 7rem;
  margin: 2rem;

  .manuscript .page {
    border-width: 2px;
    h1 {
      font-size: 0.9rem;
    }
  }
}