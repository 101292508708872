@import "../../styles/styles.scss";

.manuscript {
  //   width: 15rem;
  //   height: 17rem;
  aspect-ratio: 0.86;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: perspective(30em) rotateX(18deg) rotateZ(-5deg);

  //box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px, rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
  //border-radius: 10px;
  // border: 1px solid;
  border-color: rgb(213, 220, 226) rgb(213, 220, 226) rgb(184, 194, 204);

  .roll-base {
    position: absolute;
    transition: transform 0.7s ease-in-out;
    z-index: 2;
  }

  .roll-top {
    top: 0;
    transform: translateY(115%);
    transform-origin: bottom center;
  }

  .roll-bottom {
    bottom: 0;
    transform: translateY(-115%);
    transform-origin: top center;
  }

  .page {
    margin-right: 2%;
    width: 75%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    transform: scaleY(0);
    transition: transform 0.7s ease-in-out;
    overflow: hidden;
    border-left: 4px solid black;
    border-right: 4px solid black;

    h1 {
      font-size: $regular;
      color: #000;
      text-transform: uppercase;
      font-weight: bold;
      padding: 20px;
      text-align: center;
    }
  }
}

.animate {
  .roll-top {
    transform: translateY(0%);
  }

  .roll-bottom {
    transform: translateY(0%);
  }

  .page {
    transform: scaleY(1);
  }
}